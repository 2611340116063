<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { useField, useForm } from 'vee-validate';
import * as yup from 'yup';

import * as client from '@gabrielcam/api-client';

import { useApplicationStore } from '@stores/application';
import { ButtonType, ButtonVariant } from '@viewModels/enums';

import ButtonComponent from '@components/ButtonComponent.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import Heading from '@components/Heading.vue';
import SubHeader from '@components/SubHeader.vue';
import ButtonActions from '@layouts/ButtonActions.vue';

interface CameraProvisioningForm {
  provisioningCode: string;
}

const schema = yup.object({
  provisioningCode: yup.string().test({
    name: 'is-provisioning-code',
    test(value, ctx) {
      if (!value) {
        return ctx.createError({ message: 'Provisioning code is required' });
      }
      if (!value.match(/^[0-9]+$/)) {
        return ctx.createError({ message: 'Provisioning code can only contain numbers' });
      }
      if (value.length !== 6) {
        return ctx.createError({ message: 'Provisioning code must be 6 digits' });
      }
      return true;
    },
  }),
});

const { handleSubmit } = useForm<CameraProvisioningForm>({
  validationSchema: schema,
});

const { value: provisioningCodeValue, errorMessage: provisioningCodeError } = useField<string>(
  'provisioningCode',
  'provisioningCode',
  {
    validateOnValueUpdate: false,
  }
);

const { value: serialNumberValue, errorMessage: serialNumberError } = useField<string>('serialNumber', 'serialNumber', {
  validateOnValueUpdate: false,
});

const route = useRoute();
const router = useRouter();
const applicationStore = useApplicationStore();
const form = ref<InstanceType<typeof HTMLFormElement>>();
const isSubmitting = ref<boolean>(false);

const onSubmit = handleSubmit(async (values: any) => {
  isSubmitting.value = true;

  try {
    const camera = await client.createCamera({
      requestBody: {
        provisioningCode: values.provisioningCode,
        organisation: applicationStore.activeOrganisation?.id,
        serialNumber: values.serialNumber,
      },
    });

    if (!camera) {
      console.error('Failed to provision camera');
    }

    applicationStore.publishSuccessNotification({
      text: 'Successfully provisioned.',
      autoCloseMs: 3000,
    });

    setTimeout(() => {
      router.push({ name: 'camera-default', params: { id: camera.id } });
    }, 1000);
  } catch (error: any) {
    console.error(error);
    applicationStore.publishErrorNotification({
      text: error.body.error.message || 'Failed to provision camera',
      autoCloseMs: 3000,
    });
  }
  isSubmitting.value = false;
});

onMounted(() => {
  const provisioningCodeParam = route.params['code'] && String(route.params['code']);

  if (provisioningCodeParam) {
    provisioningCodeValue.value = provisioningCodeParam;
  }
});
</script>

<template>
  <SubHeader heading="Provision Camera"
             level="2" />

  <ContainerCard>
    <form ref="form"
          @submit="onSubmit">
      <div class="field-group">
        <div class="field-group-info">
          <Heading level="3">
            Provisioning code
          </Heading>
          <p>Enter the code provided to link it to your account.</p>
        </div>

        <div class="fields">
          <div class="row-half">
            <div class="field">
              <label for="provisioning-code">Provisioning code</label>
              <input id="provisioning-code"
                     v-model="provisioningCodeValue"
                     inputmode="numeric"
                     type="text"
                     maxlength="6">
              <p class="message message-error">
                {{ provisioningCodeError }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="field-group">
        <div class="field-group-info">
          <Heading level="3">
            Serial number
          </Heading>
          <p>Enter a label/serial number to help you identify this device</p>
        </div>

        <div class="fields">
          <div class="row-half">
            <div class="field">
              <label for="serial-number">Serial number</label>
              <input id="serial-number"
                     v-model="serialNumberValue"
                     inputmode="numeric"
                     type="text"
                     maxlength="6">
              <p class="message message-error">
                {{ serialNumberError }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <ButtonActions>
        <ButtonComponent :variant="ButtonVariant.Dark"
                         :disabled="isSubmitting"
                         :is-block-btn="true"
                         :type="ButtonType.Submit">
          Provision
        </ButtonComponent>
      </ButtonActions>
    </form>
  </ContainerCard>
</template>
