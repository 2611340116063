<script lang="ts" setup>
import { ref } from 'vue';

import { useClipboard } from '@vueuse/core';

import { ButtonSize, ButtonVariant } from '@viewModels/enums';
import { IconName, IconStyle } from '@viewModels/heroIcons';

import ButtonComponent from '@components/ButtonComponent.vue';

const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  value: {
    type: String,
    required: false,
    default: 'UNKNOWN',
  },
  copyable: {
    type: Boolean,
    required: false,
    default: true,
  },
  format: {
    type: Function,
    required: false,
    default: (value: string) => value,
  },
  link: {
    type: String,
    required: false,
    default: '',
  },
});

const { copy, isSupported } = useClipboard();

const copied = ref(false);

const copyToClipboard = (value: string): void => {
  if (isSupported) {
    copy(value).then(() => {
      copied.value = true;
      setTimeout(() => {
        copied.value = false; // Reset to "Copy" after 2 seconds
      }, 2000);
    });
  }
};
</script>

<template>
  <div class="details-grid__item">
    <div class="details-grid__details-container">
      <span class="details-grid__label">{{ props.label }}:</span>
      <span class="details-grid__value">{{ props.format(props.value) }}</span>
    </div>

    <a v-if="props.link"
       :href="props.link"
       target="_blank">
      <ButtonComponent :is-icon-btn="true"
                       :is-outline-btn="true"
                       :icon-style="IconStyle.Outline"
                       :icon-name="IconName.ArrowTopRightOnSquareIcon"
                       :size="ButtonSize.Small"
                       :variant="ButtonVariant.Dark" />
    </a>

    <ButtonComponent v-if="props.copyable"
                     :is-icon-btn="true"
                     :is-outline-btn="!copied"
                     :icon-style="IconStyle.Outline"
                     :icon-name="copied ? IconName.CheckIcon : IconName.ClipboardIcon"
                     :size="ButtonSize.Small"
                     :variant="copied ? ButtonVariant.Success : ButtonVariant.Dark"
                     @click="copyToClipboard(props.value)" />
  </div>
</template>

<style lang="scss" scoped>
@use '@scss/variables' as *;

.details-grid {
  &__item {
    display: flex;
    gap: 5px 20px;
    align-items: center;
    padding: 10px 0;
    border-top: 1px solid $neutral-200;

    // Hide the top border on the first item when we have one column
    &:nth-child(1) {
      border-top: 0;
    }
  }

  &__details-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__label {
    font-weight: bold;
    white-space: nowrap;
  }

  &__value {
    word-break: break-all;
    overflow-wrap: break-word;
  }
}
</style>
