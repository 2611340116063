<script setup lang="ts">
import { computed, PropType, ref, watch } from 'vue';

import moment from 'moment';
import 'v-calendar/style.css';

import * as client from '@gabrielcam/api-client';

import { IconName, IconStyle } from '@viewModels/heroIcons';

import ButtonComponent from '@components/ButtonComponent.vue';
import { useMobileDetection } from '@utils/isMobile';
import DatePickerHolder from '@components/viewer/DatePickerHolder.vue';
import { DatePickerTheme } from '@viewModels/enums';

const { isMobile } = useMobileDetection();

const props = defineProps({
  dates: {
    type: Object as PropType<string[]>,
    required: true,
  },
  selectedDate: {
    type: String,
    required: true,
  },
  images: {
    type: Object as PropType<Map<string, client.Image[]>>,
    required: true,
  },
  selectedImage: {
    type: Object as PropType<client.Image>,
    required: true,
  },
});

const emit = defineEmits<{
  (e: 'imageSelected', id: client.Image): void;
  (e: 'selectedDateChanged', id: string): void;
}>();

const today = new Date().toISOString().split('T')[0]!;
const selectedDateString = ref(props.dates.at(-1) || today);
const imageElements = ref<Map<string, HTMLElement>>(new Map<string, HTMLElement>());

watch(
  () => props.selectedDate,
  () => {
    catalogueDateInput.value = props.selectedDate;
    selectedDateString.value = props.selectedDate;
  }
);
watch(
  () => props.selectedImage,
  () => {
    const selectedImageElement = imageElements.value.get(props.selectedImage.id);
    if (!selectedImageElement) {
      console.warn('Image element not defined');
      return;
    }
    selectedImageElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
);

function addImageElement(id: string, element: HTMLElement | undefined): void {
  if (element) imageElements.value.set(id, element);
}
const catalogueDateInput = computed({
  get() {
    return selectedDateString.value;
  },
  set(newValue) {
    selectedDateString.value = newValue;
    emit('selectedDateChanged', selectedDateString.value);
  },
});

function scrollCatalogue(direction: string): void {
  const catalogue: any = document.querySelector('.viewer-catalogue-images');
  if (direction === 'up') {
    catalogue.scrollTop -= catalogue.offsetHeight * 0.9;
  }
  if (direction === 'down') {
    catalogue.scrollTop += catalogue.offsetHeight * 0.9;
  }
}

function selectImage(image: client.Image): void {
  if (!image) return;
  emit('imageSelected', image);
}
</script>

<template>
  <div class="viewer-catalogue">
    <DatePickerHolder v-model="selectedDateString"
                      :variant="isMobile ? DatePickerTheme.Light : DatePickerTheme.Dark"
                      :available-dates="dates"
                      :selected-date="selectedDateString"
                      @update:model-value="
                        (e: string) => {
                          catalogueDateInput = e;
                        }
                      " />
    
    <div class="viewer-catalogue-images">
      <ButtonComponent class="viewer-button viewer-button-up"
                       :is-icon-btn="true"
                       :icon-name="IconName.ChevronUpIcon"
                       :icon-style="IconStyle.Outline"
                       aria-label="Scroll Up"
                       @click="scrollCatalogue('up')" />

      <div class="viewer-catalogue-images-container">
        <div v-for="image in images.get(catalogueDateInput)"
             :id="'viewer-catalogue-item-' + image.id"
             :key="image.id"
             :ref="(e: any) => addImageElement(image.id, e)"
             class="viewer-catalogue-images-item"
             :class="{ 'viewer-catalogue-images-item-active': selectedImage?.id == image.id }"
             @click="selectImage(image)">
          <img :src="image.sourceURL + '?w=300'"
               aria-hidden="true"
               :alt="image.originalFileName">

          <p class="viewer-catalogue-images-item-date">
            {{ moment(image.capturedAt).format('h:mm:ss a') }}
          </p>
        </div>
      </div>

      <ButtonComponent class="viewer-button viewer-button-down"
                       :is-icon-btn="true"
                       :icon-name="IconName.ChevronDownIcon"
                       :icon-style="IconStyle.Outline"
                       aria-label="Scroll Down"
                       @click="scrollCatalogue('down')" />
    </div>
  </div>
</template>
