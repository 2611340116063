import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

import authenticationRequired from '@middleware/authentication-required';
import checkPublicView from '@middleware/check-public-view';
import hubOnly from '@middleware/hub-only';
import organisationRequired from '@middleware/organisation-required';

import { useMobileDetection } from '@utils/isMobile';

import { PageNames } from '@viewModels/enums';

import DefaultLayout from '@layouts/DefaultLayout.vue';
import ErrorLayout from '@layouts/ErrorLayout.vue';
import Account from '@pages/account.vue';
import Admin from '@pages/admin/admin.vue';
import CameraDirectory from '@pages/cameras.vue';
import Camera from '@pages/cameras/[id].vue';
import CameraApi from '@pages/cameras/[id]/camera-api.vue';
import CameraDevice from '@pages/cameras/[id]/camera-device.vue';
import CameraLogs from '@pages/cameras/[id]/camera-logs.vue';
import CameraNetwork from '@pages/cameras/[id]/camera-network.vue';
import CameraPower from '@pages/cameras/[id]/camera-power.vue';
import CameraSchedule from '@pages/cameras/[id]/camera-schedule.vue';
import CameraSettings from '@pages/cameras/[id]/camera-settings.vue';
import CameraStatus from '@pages/cameras/[id]/camera-status.vue';
import CameraCreate from '@pages/cameras/create.vue';
import CameraProvision from '@pages/cameras/provision.vue';
import CameraRegister from '@pages/cameras/register.vue';
import ChangePassword from '@pages/ChangePassword.vue';
import Events from '@pages/events.vue';
import Forgot from '@pages/forgot.vue';
import Login from '@pages/login.vue';
import Logout from '@pages/logout.vue';
import OrganisationsDirectory from '@pages/organisations.vue';
import Organisation from '@pages/organisations/[id].vue';
import OrganisationSettings from '@pages/organisations/[id]/edit.vue';
import Registration from '@pages/register.vue';
import SubscriptionClientSettings from '@pages/subscriptions/clients/[id]/edit.vue';
import SubscriptionClientCreation from '@pages/subscriptions/clients/create.vue';
import SubscriptionProject from '@pages/subscriptions/projects/[id].vue';
import SubscriptionProjectSettings from '@pages/subscriptions/projects/[id]/edit.vue';
import SubscriptionProjectCreation from '@pages/subscriptions/projects/create.vue';
import SubscriptionClients from '@pages/subscriptions/subscription-clients.vue';
import SubscriptionClientDirectory from '@pages/subscriptions/subscription-clients.vue';
import SubscriptionLicenses from '@pages/subscriptions/subscription-licenses.vue';
import SubscriptionNotifications from '@pages/subscriptions/subscription-notifications.vue';
import SubscriptionProjects from '@pages/subscriptions/subscription-projects.vue';
import SubscriptionViews from '@pages/subscriptions/subscription-views.vue';
import Subscriptions from '@pages/subscriptions/subscriptions.vue';
import UsersDirectory from '@pages/users.vue';
import User from '@pages/users/[id].vue';
import UserEdit from '@pages/users/[id]/user-edit.vue';
import UserRoles from '@pages/users/[id]/user-roles.vue';
import UserInvitation from '@pages/users/invite.vue';
import VideoDirectory from '@pages/videos.vue';
import VideoCreation from '@pages/videos/create.vue';
import Views from '@pages/views.vue';
import View from '@pages/views/[id].vue';
import ViewDownloads from '@pages/views/[id]/view-downloads.vue';
import ViewGallery from '@pages/views/[id]/view-gallery.vue';
import ViewInformation from '@pages/views/[id]/view-information.vue';
import ViewVideos from '@pages/views/[id]/view-videos.vue';
import ViewViewer from '@pages/views/[id]/view-viewer.vue';
import PublicViewViewer from '@pages/views/public/[id].vue';
import ViewMap from '@pages/views/view-map.vue';
import ViewNew from '@pages/views/view-new.vue';
import WelcomePage from '@pages/welcome-page.vue';

const isMobile = useMobileDetection();

const routes: RouteRecordRaw[] = [
  {
    path: '/p/:code',
    redirect: {
      name: PageNames.CameraProvision,
    },
  },
  {
    path: '/login',
    name: PageNames.Login,
    component: Login,
    meta: {
      public: true,
      title: 'Login',
    },
  },
  {
    path: '/logout',
    name: PageNames.Logout,
    component: Logout,
    meta: {
      public: true,
      title: 'Logout',
    },
  },
  {
    path: '/register',
    name: PageNames.Register,
    component: Registration,
    meta: {
      public: true,
      title: 'Register',
    },
  },
  {
    path: '/forgot',
    name: PageNames.Forgot,
    component: Forgot,
    meta: {
      public: true,
      title: 'Forgot Password',
    },
  },
  {
    path: '/ChangePassword',
    name: PageNames.ChangePassword,
    component: ChangePassword,
    meta: {
      public: true,
      title: 'Change Password',
    },
  },
  {
    path: '/welcome',
    name: PageNames.Welcome,
    component: WelcomePage,
    meta: {
      title: 'Welcome',
    },
  },
  {
    path: '/',
    name: PageNames.Home,
    component: DefaultLayout,
    meta: {
      title: 'Home',
    },
    children: [
      {
        path: 'account',
        name: PageNames.Account,
        component: Account,
        meta: {
          title: 'Account',
        },
      },
      {
        path: 'videos/:viewId?',
        name: PageNames.Videos,
        component: VideoDirectory,
        meta: {
          hubOnly: true,
          title: 'Videos',
        },
      },
      {
        path: '/videos/:viewId?/new',
        name: PageNames.VideoNew,
        component: VideoCreation,
        meta: {
          hubOnly: true,
          title: 'New Video',
        },
      },
      {
        path: 'organisations',
        name: PageNames.Organisations,
        component: OrganisationsDirectory,
        meta: {
          hubOnly: true,
          title: 'Organisations',
        },
      },
      {
        path: 'organisations/:id',
        name: PageNames.Organisation,
        component: Organisation,
        meta: {
          hubOnly: true,
          title: 'Organisation',
        },
        children: [
          {
            path: 'edit',
            name: PageNames.OrganisationSettings,
            component: OrganisationSettings,
            meta: {
              title: 'Edit Organisation',
            },
          },
        ],
      },
      {
        path: 'users',
        name: PageNames.Users,
        component: UsersDirectory,
        meta: {
          hubOnly: true,
          title: 'Users',
        },
      },
      {
        path: 'users/invite',
        name: PageNames.UserInvitation,
        component: UserInvitation,
        meta: {
          hubOnly: true,
          title: 'Invite User',
        },
      },
      {
        path: 'users/:id',
        name: PageNames.User,
        component: User,
        meta: {
          hubOnly: true,
          title: 'Users',
        },
        children: [
          {
            path: 'edit',
            name: PageNames.UserEdit,
            component: UserEdit,
            meta: {
              hubOnly: true,
              title: 'Edit User',
            },
          },
          {
            path: 'roles',
            name: PageNames.UserRoles,
            component: UserRoles,
            meta: {
              hubOnly: true,
              title: 'User Roles',
            },
          },
        ],
      },
      {
        path: 'cameras',
        name: PageNames.Cameras,
        component: CameraDirectory,
        meta: {
          hubOnly: true,
          title: 'Cameras',
        },
      },
      {
        path: 'cameras/register',
        name: PageNames.CameraRegister,
        component: CameraRegister,
        meta: {
          hubOnly: true,
          title: 'Register Camera',
        },
      },
      {
        path: 'cameras/create',
        name: PageNames.CameraNew,
        component: CameraCreate,
        meta: {
          hubOnly: true,
          title: 'Create Camera',
        },
      },
      {
        path: 'cameras/provision/:code?',
        name: PageNames.CameraProvision,
        component: CameraProvision,
        meta: {
          hubOnly: true,
          title: 'Provision Camera',
        },
      },
      {
        path: 'cameras/:id',
        name: PageNames.Camera,
        component: Camera,
        meta: {
          hubOnly: true,
          title: 'Camera',
        },
        children: [
          {
            path: '',
            name: PageNames.CameraStatus,
            component: CameraStatus,
            meta: {
              title: 'Status',
            },
          },
          {
            path: 'status',
            name: PageNames.CameraStatus,
            component: CameraStatus,
            meta: {
              title: 'Status',
            },
          },
          {
            path: 'device',
            name: PageNames.CameraDevice,
            component: CameraDevice,
            meta: {
              title: 'Device',
            },
          },
          {
            path: 'settings',
            name: PageNames.CameraSettings,
            component: CameraSettings,
            meta: {
              title: 'Camera Settings',
            },
          },
          {
            path: 'power',
            name: PageNames.CameraPower,
            component: CameraPower,
            meta: {
              title: 'Power',
            },
          },
          {
            path: 'logs',
            name: PageNames.CameraLogs,
            component: CameraLogs,
            meta: {
              title: 'Logs',
            },
          },
          {
            path: 'network',
            name: PageNames.CameraNetwork,
            component: CameraNetwork,
            meta: {
              title: 'Network',
            },
          },
          {
            path: 'schedule',
            name: PageNames.CameraSchedule,
            component: CameraSchedule,
            meta: {
              title: 'Schedule',
            },
          },
          {
            path: 'api',
            name: PageNames.CameraAPI,
            component: CameraApi,
            meta: {
              title: 'API',
            },
          },
        ],
      },
      {
        path: 'admin',
        name: PageNames.Admin,
        component: Admin,
        meta: {
          hubOnly: true,
          title: 'Admin',
        },
      },
      {
        path: 'views',
        name: PageNames.Views,
        component: Views,
        meta: {
          title: 'Views',
        },
      },
      {
        path: 'views/map/:viewId?',
        name: PageNames.ViewMap,
        component: ViewMap,
        meta: {
          title: 'Map View',
        },
      },
      {
        path: 'views/new',
        name: PageNames.ViewNew,
        component: ViewNew,
        meta: {
          hubOnly: true,
          title: 'New View',
        },
      },
      {
        path: 'views/public/:id',
        name: PageNames.ViewPublic,
        component: PublicViewViewer,
        meta: {
            public: true,
            title: 'View Viewer',
        },
      },
      {
        path: 'views/:id',
        name: PageNames.View,
        component: View,
        meta: {
          title: 'View',
        },
        children: [
          {
            path: 'gallery',
            name: PageNames.ViewGallery,
            component: ViewGallery,
            meta: {
              title: 'View Gallery',
            },
          },
          {
            path: 'information',
            name: PageNames.ViewInformation,
            component: ViewInformation,
            meta: {
              hubOnly: true,
              title: 'View Information',
            },
          },
          {
            path: 'videos',
            name: PageNames.ViewVideos,
            component: ViewVideos,
            meta: {
              hubOnly: true,
              title: 'View Videos',
            },
          },
          {
            path: 'downloads',
            name: PageNames.ViewDownloads,
            component: ViewDownloads,
            meta: {
              hubOnly: true,
              title: 'View Downloads',
            },
          },
          {
            path: '',
            name: PageNames.ViewViewer,
            component: ViewViewer,
            beforeEnter: checkPublicView,
            meta: {
              public: true,
              title: 'View Viewer',
            },
          },
        ],
      },
      {
        path: 'events',
        name: PageNames.Events,
        component: Events,
        meta: {
          hubOnly: true,
          title: 'Events',
        },
      },
      {
        path: 'subscriptions',
        name: PageNames.Subscriptions,
        component: Subscriptions,
        meta: {
          hubOnly: true,
          title: 'Subscriptions',
        },
      },
      {
        path: 'subscriptions/clients',
        name: PageNames.Clients,
        component: SubscriptionClientDirectory,
        meta: {
          hubOnly: true,
          title: 'Clients',
        },
      },
      {
        path: 'subscriptions/clients/new',
        name: PageNames.ClientCreate,
        component: SubscriptionClientCreation,
        meta: {
          hubOnly: true,
          title: 'Create Client',
        },
      },
      {
        path: 'subscriptions/client/:id/edit',
        name: PageNames.ClientSettings,
        component: SubscriptionClientSettings,
        meta: {
          hubOnly: true,
          title: 'Edit Client',
        },
      },
      {
        path: 'subscriptions/client/:id/projects/new',
        name: PageNames.ClientProjectsCreate,
        component: SubscriptionProjectCreation,
        meta: {
          hubOnly: true,
          title: 'Create Project',
        },
      },
      {
        path: 'subscriptions/notifications',
        name: PageNames.SubscriptionNotifications,
        component: SubscriptionNotifications,
        meta: {
          hubOnly: true,
          title: 'Subscription Notifications',
        },
      },
      {
        path: 'subscriptions/views',
        name: PageNames.SubscriptionViews,
        component: SubscriptionViews,
        meta: {
          hubOnly: true,
          title: 'Subscription Views',
        },
      },
      {
        path: 'subscriptions/projects',
        name: PageNames.SubscriptionProjects,
        component: SubscriptionProjects,
        meta: {
          hubOnly: true,
          title: 'Subscription Projects',
        },
      },
      {
        path: 'subscriptions/projects',
        name: PageNames.Projects,
        component: SubscriptionProjects,
        meta: {
          hubOnly: true,
          title: 'Projects',
        },
      },
      {
        path: 'subscriptions/projects/:id',
        name: PageNames.Project,
        component: SubscriptionProject,
        meta: {
          hubOnly: true,
          title: 'Project',
        },
      },
      {
        path: 'subscriptions/projects/:id/edit',
        name: PageNames.ProjectEdit,
        component: SubscriptionProjectSettings,
        meta: {
          hubOnly: true,
          title: 'Edit Project',
        },
      },
      {
        path: 'subscriptions/projects/new',
        name: PageNames.ProjectCreate,
        component: SubscriptionProjectCreation,
        meta: {
          hubOnly: true,
          title: 'Create Project',
        },
      },
      {
        path: 'subscriptions/clients',
        name: PageNames.SubscriptionClients,
        component: SubscriptionClients,
        meta: {
          hubOnly: true,
          title: 'Subscription Clients',
        },
      },
      {
        path: 'subscriptions/licenses',
        name: PageNames.SubscriptionLicenses,
        component: SubscriptionLicenses,
        meta: {
          hubOnly: true,
          title: 'Subscription Licenses',
        },
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    name: PageNames.NotFound,
    component: ErrorLayout,
    meta: {
      public: true,
      title: 'Not Found',
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  // Handle the scrolling behavior after navigation
  scrollBehavior(_, __, savedPosition) {
    return savedPosition || { top: 0 };
  },
});

router.beforeEach((_, __, next) => {
  if (isMobile) {
    // Scroll to the top
    window.scrollTo({ top: 0, behavior: 'smooth' });

    // Check if the scroll is complete before navigation
    const checkIfScrollComplete = (): void => {
      if (window.scrollY === 0) {
        next();
      } else {
        requestAnimationFrame(checkIfScrollComplete);
      }
    };
    // Start the scroll completion check
    requestAnimationFrame(checkIfScrollComplete);
  } else {
    // If not mobile, navigate immediately
    next();
  }
});

router.beforeEach(authenticationRequired);
router.beforeEach(organisationRequired);
router.beforeEach(hubOnly);

export default router;
