<script lang="ts" setup>
import { computed, onMounted, onUnmounted, ref } from 'vue';

import dayjs from 'dayjs';

import * as client from '@gabrielcam/api-client';

import { useViewStore } from '@stores/view';
import { ButtonVariant } from '@viewModels/enums';
import { IconName, IconPosition, IconStyle } from '@viewModels/heroIcons';

import ButtonComponent from '@components/ButtonComponent.vue';
import Heading from '@components/Heading.vue';

import BatteryChartPanel from './BatteryChartPanel.vue';
import EnergyChartDatePicker from './EnergyChartDatePicker.vue';
import EquipmentChartPanel from './EquipmentChartPanel.vue';
import PowerChartPanel from './PowerChartPanel.vue';

// Enums and Interfaces
enum QuickSelectMode {
  PastHour = 'PASTHOUR',
  Past12Hours = 'PAST12HOURS',
  Past24Hours = 'PAST24HOURS',
}

interface DateRange {
  start: dayjs.Dayjs;
  end: dayjs.Dayjs;
}

// Props
const props = defineProps({
  viewId: {
    type: String,
    required: true,
  },
});

// Store and Reactive Variables
const viewStore = useViewStore();
const loading = ref<boolean>(true);
const statsUnauthorised = ref<boolean>(false);
const collapsed = ref<boolean>(true);
const stats = ref<client.CameraStat[]>([]);
const mode = ref<QuickSelectMode | null>(null);
const isDatePickerActive = ref(false);
const today = dayjs().endOf('day');
const oneWeekAgo = dayjs().subtract(1, 'day').startOf('day');
const dateRange = ref<DateRange>({ start: oneWeekAgo, end: today });
let pollingTask: NodeJS.Timeout;

const modeFunctions = {
  [QuickSelectMode.PastHour]: quickSelectOneHour,
  [QuickSelectMode.Past12Hours]: quickSelectTwelveHours,
  [QuickSelectMode.Past24Hours]: quickSelectTwentyFourHours,
};

const modeTexts = {
  [QuickSelectMode.PastHour]: 'Viewing Past Hour',
  [QuickSelectMode.Past12Hours]: 'Viewing Past 12 Hours',
  [QuickSelectMode.Past24Hours]: 'Viewing Past 24 Hours',
};

// Computed Variables
const selectedModeText = computed(() => {
  if (isDatePickerActive.value) {
    return `Viewing: ${dateRange.value.start.format('DD MMM YYYY')} - ${dateRange.value.end.format('DD MMM YYYY')}`;
  } else if (mode.value !== null) {
    return modeTexts[mode.value] || '';
  } else {
    return '';
  }
});

// Lifecycle Hooks
onMounted(async () => {
  try {
    mode.value = QuickSelectMode.PastHour;
    await quickSelectOneHour();
    await pollData();
  } catch (error: any) {
    if (error.status === 401) {
      statsUnauthorised.value = true;
    }
  }
  loading.value = false;
});

onUnmounted(() => {
  if (pollingTask) {
    clearInterval(pollingTask);
  }
});

// Methods
async function updateData(): Promise<void> {
  stats.value = [];
  const currentView = await viewStore.getViewById(props.viewId);
  if (!currentView?.camera) return;
  // console.log(dateRange.value.start.toISOString() + ' to ' + dateRange.value.end.toISOString());
  const currentStats = await client.listCameraByIdStats({
    cameraId: currentView.camera,
    startDate: dateRange.value.start.toISOString(),
    endDate: dateRange.value.end.toISOString(),
  });

  console.table(currentStats.data);

  while (currentStats.data.length > 200) {
    currentStats.data = reduceMeasurements(currentStats.data); // Crude way to reduce the amount on the UI
  }
  stats.value = currentStats.data;
}

async function pollData(): Promise<void> {
  if (pollingTask) {
    clearInterval(pollingTask);
  }

  // Refresh the data every minute
  pollingTask = setInterval(async () => {
    // Skip polling if the date picker is active or mode is null
    if (isDatePickerActive.value || mode.value === null) return;

    const modeFunction = modeFunctions[mode.value];
    if (modeFunction) {
      await modeFunction();
    }
  }, 30000);
}

async function selectMode(selectedMode: QuickSelectMode): Promise<void> {
  // Reset the date picker state
  isDatePickerActive.value = false;

  mode.value = selectedMode;
  const modeFunction = modeFunctions[selectedMode];
  if (modeFunction) {
    await modeFunction();
  }
}

async function onUpdateDateRange(e: DateRange): Promise<void> {
  loading.value = true;
  collapsed.value = true;
  dateRange.value = e;
  await updateData();
  loading.value = false;
}

function toggleDatePicker(): void {
  isDatePickerActive.value = true;
  collapsed.value = !collapsed.value;
  // Reset the active button
  mode.value = null;
}

function isActiveMode(checkMode: QuickSelectMode): boolean {
  return mode.value === checkMode && !isDatePickerActive.value;
}

async function quickSelectOneHour(): Promise<void> {
  mode.value = QuickSelectMode.PastHour;
  let now = dayjs();
  let oneHourAgo = dayjs().subtract(1, 'hour');
  await onUpdateDateRange({ start: oneHourAgo, end: now });
}

async function quickSelectTwelveHours(): Promise<void> {
  mode.value = QuickSelectMode.Past12Hours;
  let now = dayjs();
  let twelveHoursAgo = dayjs().subtract(12, 'hours');
  await onUpdateDateRange({ start: twelveHoursAgo, end: now });
}

async function quickSelectTwentyFourHours(): Promise<void> {
  mode.value = QuickSelectMode.Past24Hours;
  let now = dayjs();
  let twentyFourHoursAgo = dayjs().subtract(24, 'hours');
  await onUpdateDateRange({ start: twentyFourHoursAgo, end: now });
}

function reduceMeasurements(cameraStats: client.CameraStat[]): client.CameraStat[] {
  const stats: client.CameraStat[] = [];
  const num = 3;
  for (let index = 0; index < cameraStats.length; index++) {
    if (index % num != 0) continue;
    stats.push(cameraStats[index]!);
  }
  return stats;
}
</script>

<template>
  <div v-if="!statsUnauthorised">
    <Heading level="3"
             :has-bottom-margin="true">
      {{ selectedModeText }}
    </Heading>

    <p class="mb-10">
      <strong>Filter by range:</strong>
    </p>

    <div class="d-flex flex-wrap flex-lg-row gap-20 align-start mb-20 align-center">
      <div class="w-100 w-lg-auto">
        <ButtonComponent :variant="ButtonVariant.Dark"
                         :is-block-btn="true"
                         :is-outline-btn="true"
                         :icon-position="IconPosition.Right"
                         :icon-style="IconStyle.Outline"
                         :icon-name="IconName.ChevronDownIcon"
                         :is-dropdown-btn="true"
                         :class="{ 'mb-10': !collapsed }"
                         @click="toggleDatePicker">
          {{ dateRange.start.format('DD MMM YYYY') }} - {{ dateRange.end.format('DD MMM YYYY') }}
        </ButtonComponent>
        <EnergyChartDatePicker v-if="!collapsed"
                               :model-value="dateRange"
                               @update:model-value="onUpdateDateRange" />
      </div>

      <ButtonComponent :variant="ButtonVariant.Dark"
                       :is-block-btn="true"
                       :is-outline-btn="true"
                       :is-active-btn="isActiveMode(QuickSelectMode.PastHour)"
                       @click="selectMode(QuickSelectMode.PastHour)">
        Past hour
      </ButtonComponent>

      <ButtonComponent :variant="ButtonVariant.Dark"
                       :is-block-btn="true"
                       :is-outline-btn="true"
                       :is-active-btn="isActiveMode(QuickSelectMode.Past12Hours)"
                       @click="selectMode(QuickSelectMode.Past12Hours)">
        Past 12 hours
      </ButtonComponent>

      <ButtonComponent :variant="ButtonVariant.Dark"
                       :is-block-btn="true"
                       :is-outline-btn="true"
                       :is-active-btn="isActiveMode(QuickSelectMode.Past24Hours)"
                       @click="selectMode(QuickSelectMode.Past24Hours)">
        Past 24 hours
      </ButtonComponent>
    </div>
  </div>

  <div v-if="!statsUnauthorised">
    <EquipmentChartPanel :loading="loading"
                         :stats="stats" />
    <BatteryChartPanel :loading="loading"
                       :stats="stats" />
    <PowerChartPanel :loading="loading"
                     :stats="stats" />
  </div>
</template>
