<script lang="ts" setup>
import router from '@/router';

import { ButtonVariant } from '@viewModels/enums';
import { IconName, IconStyle } from '@viewModels/heroIcons';

import ButtonComponent from '@components/ButtonComponent.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import Heading from '@components/Heading.vue';
import Loading from '@components/Loading.vue';

export interface SubNavItem {
  to?: string;
  title: string;
  active?: boolean;
  viewPermission?: boolean;
  iconName?: IconName;
  iconStyle?: IconStyle;
}

const props = defineProps<{
  heading?: string;
  headingLevel: '1' | '2' | '3' | '4' | '5' | '6';
  subNavItems: SubNavItem[];
  isLoading: boolean;
}>();

const { heading, headingLevel, subNavItems, isLoading } = props;

function handleClick(item: SubNavItem): void {
  if (item.to && !item.active) {
    router.push(item.to);
  }
}
</script>

<template>
  <ContainerCard class="subnav">
    <Loading v-if="isLoading" />

    <template v-else>
      <template v-if="heading">
        <Heading :has-bottom-margin="true"
                 :level="headingLevel">
          {{ heading }}
        </Heading>
      </template>

      <nav class="subnav__list">
        <ButtonComponent v-for="item in subNavItems"
                         :key="item.title + item.active"
                         :disabled="!item.viewPermission"
                         :is-active-btn="item.active"
                         :is-outline-btn="true"
                         :icon-name="item.iconName"
                         :icon-style="item.iconStyle"
                         class="subnav__item"
                         :variant="ButtonVariant.Dark"
                         @click="handleClick(item)">
          {{ item.title }}
        </ButtonComponent>
      </nav>
    </template>
  </ContainerCard>
</template>

<style lang="scss" scoped>
@use '@scss/variables' as *;

.subnav {
  &__list {
    display: grid;
    grid-auto-flow: column;
    gap: $gap-default;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    scroll-padding: 0;

    @media (min-width: $breakpoint-lg) {
      display: flex;
      flex-wrap: wrap;
      overflow: hidden;
      scroll-snap-type: none;
    }

    & button[disabled] {
      position: unset;
      pointer-events: none;
      cursor: not-allowed;

      &::before,
      &::after {
        all: unset;
      }
    }
  }

  &__item {
    width: min-content;
    white-space: nowrap;
    scroll-snap-align: start;
  }
}
</style>
