<script lang="ts" setup>
import { computed, onMounted, Ref, ref } from 'vue';
import { useRoute } from 'vue-router';

import dayjs from 'dayjs';
import 'dayjs/locale/en';
import moment from 'moment';

import * as client from '@gabrielcam/api-client';

import ContainerCard from '@components/cards/ContainerCard.vue';
import Loading from '@components/Loading.vue';
import IrisViewer from '@components/viewer/IrisViewer.vue';
import ButtonComponent from '@components/ButtonComponent.vue';
import { ButtonVariant } from '@viewModels/enums';
import { storeToRefs } from 'pinia';
import { useViewStore } from '@stores/view';

const viewStore = useViewStore();
const route = useRoute();
const viewId: string = route.params['id'] as string;
const view = ref({}) as Ref<client.View>;
const allImages = ref([]) as Ref<client.Image[]>;
const availableDates = ref([]) as Ref<string[]>;
const isLoading = ref<boolean>(true);
const selectedDate = ref() as Ref<string>;
const { datePickerIsOpen } = storeToRefs(viewStore);

onMounted(async () => {
  isLoading.value = true;
  view.value = await client.getViewById({ viewId });
  // Get today's images first
  const latestImageDate = dayjs(view.value.lastCapturedUtc).format('YYYY-MM-DD');
  availableDates.value = [latestImageDate];
  selectedDate.value = latestImageDate;

  const startDate = dayjs(view.value.lastCapturedUtc).startOf('day').toISOString();
  const endDate = dayjs(view.value.lastCapturedUtc).endOf('day').toISOString();
  let images = (
    await client.listViewByIdImages({
      viewId,
      startDate,
      endDate,
      status: client.ImageStatus.COMPLETE,
    })
  ).data;
  if (images.length != 0) {
    allImages.value = images.filter((x) => x.capturedAt !== undefined);
    isLoading.value = false;
  }

  // Then get all images
  availableDates.value = (await client.listViewByIdCapturedDates({ viewId })).data;
  allImages.value = (await client.listViewByIdImages({ viewId, status: client.ImageStatus.COMPLETE })).data.filter(
    (x) => x.capturedAt !== undefined
  );

  if (images.length != 0) {
    selectedDate.value = availableDates.value.at(0) ?? '';
  }

  isLoading.value = false;
});

const imagesGroupedByDate = computed(() => {
  const grouped = new Map<string, client.Image[]>();
  allImages.value.forEach((image) => {
    if (image.capturedAt === undefined) return;
    const localTime = moment(image.capturedAt).local();
    image.capturedAt = localTime.format('YYYY-MM-DD HH:mm:ss'); //TODO Move to a viewmodel mapper
    const capturedDate = localTime.format('YYYY-MM-DD');
    if (grouped.has(capturedDate)) {
      grouped.get(capturedDate)!.push(image);
      return;
    }
    grouped.set(capturedDate, [image]);
  });
  return grouped;
});

async function getImages(): Promise<void> {
  // already loaded them all
}

function toggleDatePicker(): void {
  datePickerIsOpen.value = !datePickerIsOpen.value;
}
</script>

<template>
  <ContainerCard>
    <Loading v-if="isLoading" />
    
    <template v-else>
      <div class="d-lg-none w-100 mb-20">
        <ButtonComponent :is-block-btn="true"
                         :variant="ButtonVariant.Dark"
                         @click="toggleDatePicker">
          Open Calendar
        </ButtonComponent>
      </div>

      <IrisViewer :dates="availableDates"
                  :images="imagesGroupedByDate"
                  :init-date="availableDates[availableDates.length - 1]!"
                  :title="view.name"
                  :view="view"
                  logo=""
                  @get-images="getImages" />
    </template>
  </ContainerCard>
</template>
