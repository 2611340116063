<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

import dayjs from 'dayjs';
import { storeToRefs } from 'pinia';

import * as client from '@gabrielcam/api-client';

import { useApplicationStore } from '@stores/application';
import { AlertVariant } from '@viewModels/enums';

import AlertBanner from '@components/AlertBanner.vue';
import CameraDebugItem from '@components/camera/CameraDebugItem.vue';
import ContainerCard from '@components/cards/ContainerCard.vue';
import Heading from '@components/Heading.vue';
import Loading from '@components/Loading.vue';
import BatteryPanel from '@components/view_dashboard/BatteryPanel.vue';
import ComponentStatePanel from '@components/view_dashboard/StatePanel.vue';

type CameraDebugItemProps = InstanceType<typeof CameraDebugItem>['$props'];

// Route and Camera Information
const route = useRoute();
const cameraId = route.params['id'] as string;
const currentCamera = ref<client.Camera>();

// View Information
const linkedView = ref<client.View | undefined>();
const linkedProject = ref<client.Project | undefined>();
const linkedClient = ref<client.Client | undefined>();

const applicationStore = useApplicationStore();
const { adminMode } = storeToRefs(applicationStore);
const isAdmin = adminMode.value;

// Loading States
const isLoadingViews = ref<boolean>(false);
const isLoadingViewDetails = ref<boolean>(false);
const isLoadingCamera = ref<boolean>(true);

// Get Specific View Information
async function getView(): Promise<void> {
  isLoadingViews.value = true;

  // Fetch all views associated with the active organisation
  const viewsResult = await client.listViews({ organisation: applicationStore.activeOrganisation!.id });

  // Return early if no view is found
  if (!viewsResult.data || viewsResult.data.length === 0) {
    isLoadingViews.value = false;
    return;
  }

  // Iterate over each view returned from the API
  for (const view of viewsResult.data) {
    if (view.camera === cameraId) {
      linkedView.value = view;

      // Fetch the project and client for the current view
      linkedProject.value = await client.getProjectById({ projectId: view.project ?? '' });
      linkedClient.value = await client.getClientById({ clientId: view.client ?? '' });
      break; // Stop once the matching cameraId view is found
    }
  }

  isLoadingViews.value = false;
}

onMounted(async () => {
  try {
    currentCamera.value = await client.getCameraById({ cameraId });
    await getView();
  } catch (error) {
    console.error('Error fetching camera or view data:', error);
  } finally {
    isLoadingCamera.value = false;
  }
});

const cameraDetails = computed<CameraDebugItemProps[]>(() => [
  { label: 'Serial Number', value: currentCamera.value?.serialNumber },
  { label: 'ID', value: currentCamera.value?.id },
  { label: 'View', value: linkedView.value?.name, link: `/views/${linkedView.value?.id}`, copyable: false },
  { label: 'View ID', value: linkedView.value?.id },
  { label: 'Project', value: linkedProject.value?.name, copyable: false },
  { label: 'Project ID', value: linkedProject.value?.id },
  { label: 'Client', value: linkedClient.value?.name, copyable: false },
  { label: 'Client ID', value: linkedClient.value?.id },
  // { label: 'Client Status', value: 'Active' },
  // { label: 'Location', value: 'New York, NY' },
  {
    label: 'Created At',
    value: currentCamera.value?.createdAt,
    format: (value: string) => dayjs(value).format('YYYY-MM-DD'),
    copyable: false,
  },
  // { label: 'Last Seen', value: '2024-08-28' },
  // { label: 'Last Updated', value: '2024-08-28' },
  // { label: 'Image Count', value: '10' },
  // { label: 'Status', value: 'Active' },
  // { label: 'Air Vantage ID', value: currentCamera.value?.meta?.['airVantageId'] },
  { label: 'WP IMEI', value: currentCamera.value?.meta?.['wpImei'] as string | undefined },
  { label: 'WP Serial Number', value: currentCamera.value?.meta?.['wpSerialNumber'] as string | undefined },
  // { label: 'Air Vantage Status', value: 'Active' },
  // { label: 'Balena Cloud ID', value: '123456789' },
  // { label: 'Balena Cloud Status', value: 'Active' },
  // { label: 'Ably ID', value: '123456789' },
  // { label: 'Ably Status', value: 'Active' },
  // { label: 'MongoDB ID', value: '1234567890-mongo' },
  // { label: 'MongoDB Status', value: 'Active' },
  // { label: 'Google Cloud ID', value: '1234567890-gcloud' },
  // { label: 'Google Cloud Status', value: 'Active' },
  // { label: 'Hub Status', value: 'Active' },
  // { label: 'Hardware API Status', value: 'Active' },
  // { label: 'Image API Status', value: 'Active' },
]);
</script>

<template>
  <ContainerCard>
    <Loading v-if="isLoadingViews || isLoadingViewDetails || isLoadingCamera" />

    <template v-else>
      <AlertBanner v-if="!linkedView"
                   :variant="AlertVariant.Warning">
        <template #mainContent>
          No view found for {{ currentCamera?.serialNumber }} camera.
        </template>
      </AlertBanner>

      <template v-if="linkedView">
        <div class="d-flex flex-column flex-lg-row gap-20">
          <ComponentStatePanel v-if="linkedView"
                               :view-id="linkedView.id" />
          <BatteryPanel v-if="linkedView"
                        :view-id="linkedView.id" />
        </div>
      </template>
    </template>
  </ContainerCard>

  <ContainerCard v-if="isAdmin && linkedView">
    <Heading :has-bottom-margin="true"
             level="3">
      {{ currentCamera?.serialNumber }} Admin Panel
    </Heading>

    <div class="details-grid-container">
      <div class="details-grid">
        <template v-for="details in cameraDetails"
                  :key="details.label">
          <CameraDebugItem v-bind="details" />
        </template>
      </div>
    </div>
  </ContainerCard>
</template>

<style lang="scss" scoped>
@use '@scss/variables' as *;

.details-grid {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: clamp($gap-mobile, 3vw, $gap-desktop);
  font-size: 14px;

  &-container {
    container-type: inline-size;
    container-name: details;
  }

  // Using a @container to limit the width because of the sidenav
  @container details (min-width: 800px) {
    grid-template-columns: 1fr 1fr;
    padding: 10px 20px;
    border: 1px solid $neutral-400;
    border-radius: 8px;

    // Hide the top border on the second item when we have two columns
    &__item {
      &:nth-child(2) {
        border-top: 0;
      }
    }
  }
}
</style>
